import React from 'react';
import './assets/styles/styles.scss';
import PropTypes from 'prop-types';

const Navbar = ({ icon, navList, logoLink, scrolling }) => {
  return (
    <nav className={scrolling > 20 ? 'nav-container fixed' : 'nav-container'}>
      <a href={logoLink} className='nav-container__logo'>
        <img src={icon} alt='default brand' />
      </a>
      <ul className='nav-links'>
        {navList.map((navItem, i) => (
          <li key={i} className='nav-item'>
            <a href={navItem.action}>{navItem.name}</a>
          </li>
        ))}
        <li className='nav-item language-toggle'>
          <a href={'#toggleLang'}>EN</a>
        </li>
      </ul>
    </nav>
  );
};

Navbar.propTypes = {
  icon: PropTypes.string.isRequired,
  navList: PropTypes.arrayOf(PropTypes.object),
  logoLink: PropTypes.string.isRequired,
  scrolling: PropTypes.number.isRequired,
};

Navbar.defaultProps = {
  navList: [{ name: 'home', action: '/' }],
};

export default Navbar;
