import React from 'react';
import './Website/assets/scss/main.scss';
import Route from "./Route";

class App extends React.Component {
    render() {
        return(
          <React.Fragment>
            <Route/>
          </React.Fragment>
        )
    }
}
export default App;
