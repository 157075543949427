import React from 'react';
import '../assets/scss/getStarted.scss';
import PropTypes from 'prop-types';
import BG from './../assets/images/coverBG_1.svg';
const GetStarted = ({
  title,
  subtitle,
  additionalInfo,
  googlePlayIcon,
  googlePlayLink,
}) => {
  return (
    <div className='get-started__container'>
      <img src={BG} alt={'cover'} className={'image-absolute-cover'} />
      <p className={'get-started__container__title'}>{title}</p>
      <p className={'get-started__container__subtitle'}>{subtitle}</p>
      <p className={'get-started__container__additionalInfo'}>
        {additionalInfo}
      </p>
      <a href={googlePlayLink} rel='noopener noreferrer' target='_blank'>
        <img src={googlePlayIcon} alt='google play ' />
      </a>
    </div>
  );
};

GetStarted.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  additionalInfo: PropTypes.string,
  googlePlayIcon: PropTypes.string,
  googlePlayLink: PropTypes.string,
};

GetStarted.defaultProps = {
  title: 'Provide Title',
  subtitle: 'Provide a valid subtitle here',
  additionalInfo: 'Provide some additional info about this block',
  googlePlayIcon:
    'https://www.codot.gov/safety/alcohol-and-impaired-driving/planahead/2011-assets/android-market-icon.png/image',
  googlePlayLink: 'https://www.driverchai.com',
};

export default GetStarted;
