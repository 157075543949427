import React, { useState } from 'react';
import './../assets/scss/contact-us.scss';
import PropTypes from 'prop-types';
import ContactUsIcon from './../assets/images/contact-us-main-icon.svg';

const ContactUs = ({ title, subtitle }) => {
  const [contactusSend, setContactusSend] = useState(false);
  return (
    <div className='contact-us-container' id={'contactUs'}>
      <img
        src={ContactUsIcon}
        className={'contact-us-container__icon'}
        alt={'icon'}
      />
      <p className='contact-us-container__title'>{title}</p>
      <br />
      <p className='contact-us-container__subtitle'>{subtitle}</p>
      <input
        type="'email"
        placeholder={'Write Your Email Address'}
        className='contact-us-container__input'
      />
      <br />
      <textarea
        rows={5}
        placeholder={'Describe why you want to contact us'}
        className='contact-us-container__textarea'
      />
      <br />
      <button
        onClick={() => {
          setContactusSend(true);
          setTimeout(() => setContactusSend(false), 1000);
        }}
        className='contact-us-container__submit'
      >
        {contactusSend ? 'loading...' : 'Contact Us'}
      </button>
    </div>
  );
};

ContactUs.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ContactUs.defaultProps = {
  title: 'Provide Title',
  subtitle: 'provide a long and valid subtitle',
};
export default ContactUs;
