import React from 'react';
import './../assets/scss/footer.scss';
import Phone from './../assets/images/phone.svg';
import Email from './../assets/images/email.svg';
import Location from './../assets/images/location.svg';
import LinkedIn from './../assets/images/linkedin.svg';
import Twitter from './../assets/images/twitter.svg';
import Facebook from './../assets/images/facebook.svg';
import DriverChaiLogoWhite from './../assets/images/driverchai-logo-white.svg';

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='footer-container__header'>
        <div>
          <img src={Phone} alt={'phone'} /> &nbsp; 01680774007{' '}
        </div>
        <div>
          <img src={Email} alt={'phone'} /> &nbsp; info@driverchai.com{' '}
        </div>
        <div>
          <img src={Location} alt={'phone'} /> &nbsp; 41 Kamal Ataturk Avenue,
          Dhaka-1213{' '}
        </div>
      </div>
      <div className={'footer-container__body'}>
        <img src={DriverChaiLogoWhite} className={'logo'} alt={'logo white'} />
      </div>
      <div className={'footer-container__body'}>
        <a href={'https://www.driverchai.com'} target={'_blank'}>
          <img src={LinkedIn} alt={'linkedin'} />
        </a>
        <a href={'https://www.driverchai.com'} target={'_blank'}>
          <img src={Twitter} alt={'twitter'} />
        </a>
        <a href={'https://www.driverchai.com'} target={'_blank'}>
          <img src={Facebook} alt={'facebook'} />
        </a>
      </div>
      <br />
      <div className='footer-container__body'>
        <div className={'footer-container__body__text-center-item'}>
          {' '}
          Terms &amp; Services{' '}
        </div>
        <div className={'footer-container__body__text-center-item'}>
          {' '}
          Frequently Asked Question
        </div>
        <div className={'footer-container__body__text-center-item'}>
          {' '}
          Privacy Policy
        </div>
      </div>
      <p className={'text-center'}>
        All Right Reserve <strong>DRIVER CHAI</strong> &copy; 2019
      </p>
    </div>
  );
};

export default Footer;
