import React, {Component} from 'react';
import { Router } from "@reach/router"
import LandingPage from "./Website/Pages/LandingPage/LandingPage";
import FourOFour from "./Website/Pages/404/FourOFour";

class Route extends Component {

    render() {
        return(
          <Router>
            <LandingPage path="/" />
            <FourOFour default />
          </Router>
        )
    }
}
export default Route;
