import React, { Component } from 'react';
import './assets/scss/getStarted.scss';
import Navbar from '../../Components/Navbar/Navbar';
import logo from './../../assets/images/logo.svg';
import GetStarted from './components/GetStarted';
import GooglePlay from './assets/images/googleplay.svg';
import Features from './components/Features';
import coverFeature from './assets/images/coverBG_2.svg';
import driverFeature from './assets/images/driver-feature.svg';
import onDemandFeature from './assets/images/onDemand-feature.svg';
import addACar from './assets/images/addcar-feature.svg';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';

const navList = [
  { name: 'Get Started', action: '/#getStarted' },
  { name: 'How It Works', action: '/#howitworks' },
  { name: 'Contact Us', action: '/#contactUs' },
];

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolling: 0,
      featureData: [
        {
          image: driverFeature,
          title: 'HIRE DRIVER',
          subtitle:
            'Hire driver based on your requirement fast and easy from the app',
        },
        {
          image: onDemandFeature,
          title: 'ON DEMAND DRIVER',
          subtitle: 'Hire on demand driver for a fix time or hourly based',
        },
        {
          image: addACar,
          title: 'ADD YOUR CAR',
          subtitle: 'Add your car to get on demand or search driver faster',
        },
      ],
    };
  }

  handleScroll = () => {
    const { scrolling } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = scrolling > currentScrollPos;

    this.setState({
      scrolling: currentScrollPos,
      visible,
    });
  };

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  render() {
    return (
      <div onScroll={(e) => console.log(e)}>
        <Navbar
          icon={logo}
          scrolling={this.state.scrolling}
          logoLink={'https://www.driverchai.com'}
          navList={navList}
        />
        <GetStarted
          title={'FIND DRIVER'}
          subtitle={'EASIER THAN NEVER BEFORE'}
          additionalInfo={
            'Find Your Driver or Hire On Demand Driver in Driver Chai\n' +
            'App. Download DRIVER CHAI App today'
          }
          googlePlayIcon={GooglePlay}
          googlePlayLink={'https://www.driverchai.com'}
        />
        <div className='feature-contact__container'>
          <img src={coverFeature} className={'cover'} alt={'cover feature'} />
          <Features title={'HOW IT WORKS'} data={this.state.featureData} />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <ContactUs
            title={'Contact Us'}
            subtitle={
              'Want to know more about us? Feel free to add your email We will get in touch shortly'
            }
          />
          <br />
          <br />
        </div>
        <Footer />
      </div>
    );
  }
}
export default LandingPage;
