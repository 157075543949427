import React, { Component } from 'react';
import Subject from './assets/images/subject.svg';
import Qr from './assets/images/qr.png';
import './assets/scss/style.scss';

class FourOFour extends Component {
  render() {
    return (
      <div className={'four-o-four__container'}>
        <img src={Subject} alt={'subject'} />
        <p>
          Seems like you are lost <br /> Download{' '}
          <strong>Driver Chai App</strong> from scanning the code below
        </p>
        <img
          src={Qr}
          alt={'subject'}
          className={'four-o-four__container__qr'}
        />
      </div>
    );
  }
}
export default FourOFour;
