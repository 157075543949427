import React from 'react';
import './../assets/scss/features.scss';
import PropTypes from 'prop-types';

const Features = ({ title, data }) => {
  return (
    <div className='feature-container' id={'howitworks'}>
      <p className='feature-container__title'>{title}</p>
      <br />
      <br />
      <br />
      <br />
      <div className='feature-container__grid'>
        {data.map((feature, i) => (
          <div key={i} className='feature-container__grid__grid-item'>
            <img src={feature.image} alt={i + 'index picture'} />
            <p className='feature-container__grid__grid-item__title'>
              {feature.title}
            </p>
            <p className='feature-container__grid__grid-item__subTitle'>
              {feature.subtitle}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

Features.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

Features.defaultProps = {
  title: 'Provide Title',
  data: [
    {
      image:
        'https://www-thedome-org.exactdn.com/wp-content/uploads/2019/06/300x300-Placeholder-Image.jpg',
      title: 'Column 1',
      subtitle: 'Provide Additional Info',
    },
    {
      image:
        'https://www-thedome-org.exactdn.com/wp-content/uploads/2019/06/300x300-Placeholder-Image.jpg',
      title: 'Column 2',
      subtitle: 'Provide Additional Info',
    },
    {
      image:
        'https://www-thedome-org.exactdn.com/wp-content/uploads/2019/06/300x300-Placeholder-Image.jpg',
      title: 'Column 3',
      subtitle: 'Provide Additional Info',
    },
  ],
};
export default Features;
